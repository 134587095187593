.parpadea {

  animation-name: parpadeo;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

.reporte {
  width: 21cm !important;
  height: 30cm !important;
  margin: 2;
  position: relative;
  left: .5%;
  margin-top: 3px;
  display: block;
  clear: both;
}

.savepdf1 {
  top: 20%;
  left: 65%;
}

.savepdf1 {
  top: 10%;
  left: 65%;
}

.Logo {
  width: 160px;
  height: 30px;
  margin-left: 320px;
  margin-top: 30px;
  image-rendering: auto;
  align-items: center;
}

.encabezado {
  text-align: center;
  font-family: "PlayFairRegular";
  margin-top: 10px;
}

.subencabezado {
  text-align: center;
  font-family: "OxygenBold";
  font-size: 20px;
  margin-top: -5px;

}

.linea {
  border-top: 1px solid #ebe1d7;
  height: 2px;
  padding: 0;
  margin: 20px auto 0px auto;
}

.folioevento {
  margin-left: 650px;
  /* font-family: "OxygenBold"; */
  font-family:Verdana;
  font-weight: bolder;
  font-size: 10px;
  margin-top: 10px;
}

.imgplanner {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  padding-left: 10px;
  margin-left: 30px;
}

.img1 {
  width: 80px;
  height: 80px;
  margin-left: -15px;
  border-radius: 70%;
}

.planner {
  margin-top: 10px;
  margin-left: -10px;
  margin-bottom: 15px;
  left: 25%;
  width: 200px;
  height: 100px;
}

.plannerH {
  font-family: "OxygenRegular";
  color: #cba69a;
  text-transform: uppercase;
  font-size: small;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.plannerP1 {
  font-family: "PlayFairRegular";
  margin-top: -15px;
}

.plannerP2 {
  font-family: "OxygenBold" !important;
  margin-top: -20px;
  font-size: 17px;
  font-weight: 700;
}

.plannerP3 {
  font-family: "OxygenBold" !important;
  margin-top: -20px;
  font-size: 17px;
  font-weight: 700;
}

.cliente {
  margin-top: 5px;
  margin-left: 160px;
  margin-bottom: 15px;
  left: 25%;
  width: 300px;
  height: 120px;
  width: 350px;
  background-color: #ebe1d6;
}

.clienteT {
  font-family: "OxygenRegular";
  text-transform: uppercase;
  font-size: small;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 15px;
}

.cliente1 {
  font-family: "PlayFairRegular";
  margin-top: -10px;
  margin-left: 15px;
}

.cliente2 {
  font-family: "OxygenBold";
  margin-top: -20px;
  margin-left: 15px;
}

.cliente3 {
  font-family: "OxygenBold";
  margin-top: -20px;
  margin-left: 15px;
}

.avanconceptos {
  font-family: "PlayFairRegular";
  font-size: small;
  margin-top: 0px;
  margin-left: 20px;
}

#tablaconceptos {
  margin-left: -25px;
  height: auto;
}

#fila {
  margin-top: 10px;
  margin-left: 10px;
}

#nombreconcepto {
  font-family: "OxygenRegular";
  font-size: 12px;
  font-weight: 700;

}

.avanceconcepto {
  width: 60px;
  height: 20px;
  margin-left: 105px;
  /* margin-top: -20px; */
  margin-top: -15px;
  border: 2px solid #d6d6d6;
  ;
  border-radius: 30px;
}

.lineaconcepto {
  margin-top: 1px;
  border: 1px solid #d6d6d6;
  width: 165px;
  padding: 0;
}

.BarAvances {
  border-radius: 30px;
  border: 2px solid #d6d6d6;
  margin-top: 25px;
}

.avancesgenerales {
  font-family: "PlayFairRegular";
  font-size: small;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: auto;

}

.avancesgen {
  margin-top: -50px;
  margin-left: 10px;
  margin-bottom: 15px;
  left: 25%;
  width: 200px;
  height: 80px;
}

.avancesgenP1 {
  font-family: "OxygenRegular";
  text-transform: uppercase;
  font-size: small;
  color: #cba69a;
}

.avancesgenP2 {
  font-family: "OxygenBold";
  margin-top: -15px;
}

.avancesgenP3 {
  font-family: "OxygenRegular";
  text-transform: uppercase;
  font-size: small;
  margin-top: -15px;
  color: #cba69a;
}

.avancesgenP4 {
  font-family: "OxygenBold";
  margin-top: -15px;
}

.avancesgen1 {
  margin-top: -60px;
  margin-left: 20px;
  left: 25%;
  width: 200px;
  height: 80px;
}

.avancesgen1P1 {
  width: 150px;
  margin-left: 0px;
  margin-top: -10px;

}

.avancesgen1P2 {
  font-family: "OxygenRegular";
  text-transform: uppercase;
  font-size: small;
  margin-top: 5px;
  margin-left: 00px;
  color: #cba69a;
  text-align: center;
}

.avancesgen1P3 {
  font-family: "OxygenBold";
  margin-top: -10px;
  margin-left: 5px;
  text-align: center;
}

.avancesgen2 {
  margin-top: auto;
  margin-left: -1800px;
  margin-bottom: 15px;
  bottom: 0;
  right:0px;
  width: 200px;
  height: auto;
  position: relative;
}

.avancesgen2P1 {
  font-family: "PlayFairRegular";
  font-size: small;
  margin-top: 10px;
  margin-left: -10px;
}

.avancesgen2P2 {
  font-family: "OxygenRegular";
  font-size: small;
  margin-top: -5px;
  margin-left: -10px;
  color: #cba69a;
}

.avancesgen2P3 {
  font-family: "OxygenBold";
  margin-top: -15px;
  font-size: 15px;
  margin-left: -10px;
}


.imglocacion {
  width: 160px;
  height: 100px;
  margin-left: 0px;
  margin-top: 30px;

}

.img2 {
  width: 160px;
  height: 100px;
}

.locacion {
  margin-top: 30px;
  margin-left: 15px;
  margin-bottom: 15px;
  left: 25%;
  width: 200px;
  height: 130px;
}

.PlayFairRegular {
  font-family: "PlayFairRegular";
  margin-top: 0px;
}

.PlayFairBold {
  font-family: "PlayFairRegular";
  margin-top: 0px;
}

.PlayFairSemiBold {
  font-family: "PlayFairRegular";
  margin-top: 0px;
}


.locacionP1 {
  font-family: "PlayFairRegular";
  margin-top: 0px;
}

.locacionP2 {
  font-family: "OxygenRegular";
  color: #cba69a;
  text-transform: uppercase;
  font-size: small;
  margin-top: -10px;
}

.locacionP3 {
  font-family: "OxygenBold";
  margin-top: -10px;
  font-weight: bold;
}

.locacionP4 {
  font-family: "OxygenRegular";
  color: #cba69a;
  text-transform: uppercase;
  font-size: small;
  margin-top: -10px;
}

.locacionP5 {
  font-family: "OxygenBold";
  margin-top: -10px;
  font-weight: bold;
}

.locacion1 {
  margin-top: 50px;
  margin-left: 150px;
  margin-bottom: 15px;
  left: 25%;
  width: 275px;
  height: 100px;
}

.locacion1P1 {
  font-family: "OxygenRegular";
  color: #cba69a;
  text-transform: uppercase;
  font-size: small;
  margin-top: 30px;
}

.locacion1P2 {
  font-family: "OxygenBold";
  margin-top: -10px;
  font-size: small;
  font-weight: bold;
}

.locacion1P3 {
  font-family: "OxygenRegular";
  color: #cba69a;
  text-transform: uppercase;
  font-size: small;
  margin-top: -15px;
}

.locacion1P4 {
  font-family: "OxygenBold";
  font-weight: bold;
  margin-top: -15px;
}

.Encargado {

  position: relative;
  /*El div será ubicado con relación a la pantalla*/
  left: 1px;
  /*A la derecha deje un espacio de 0px*/
  bottom: -160px;
  /*Abajo deje un espacio de 0px*/
  height: 70px;
  /*alto del div*/
  width: auto;

}

.img3 {
  width: 60px;
  height: 40px;
  border-radius: 70px;
  /* margin-top: 140px; */
  /* margin-top: auto; */
  margin-left: 5px;
  position: relative;
  bottom: 0;
}

.encargadoP1 {
  font-family: "OxygenRegular";
  font-size: 11px;
  margin-top: 5px;
  margin-left: -20px;
}

.encargadoP2 {
  font-family: "OxygenBold";
  font-size: 10px;
  font-weight: bold;
  /* margin-top: 10px; */
  margin-left: -120px;
  margin-top: 35px;
  bottom: 0;
  position: relative;
}

.PersonalT {
  position: relative;
  /*El div será ubicado con relación a la pantalla*/
  left: 100px;
  /*A la derecha deje un espacio de 0px*/
  bottom: -160px;
  /*Abajo deje un espacio de 0px*/
  height: 70px;
  /*alto del div*/
  width: auto;

}

.personalP1 {
  font-family: "OxygenRegular";
  text-transform: uppercase;
  font-size: smaller;
  color: #cba69a;
  margin-top: 0px;
  margin-left: 50px;

  width: 100px;
  height: 40px;
}

.personalP2 {
  font-family: "OxygenBold";
  font-size: small;
  font-weight: bold;
  margin-top: 40px;
  margin-left: -20px;
  width: 25px;
}

.presupuestoP1 {
  font-family: "OxygenRegular";
  margin-top: auto;
  text-transform: uppercase;
  font-size: small;
  color: #cba69a;
  margin-left: 650px;
  position: relative;
  bottom: 0;
  margin-bottom: 400px;
}

.presupuestoP2 {
  font-family: "OxygenBold";
  font-size: small;
  margin-left: 260px;
  position: relative;
  margin-top: 15px;
}

.footer {
  margin-top: -6%;
  width: 21cm;
  height: .7cm;
  position: absolute;
  bottom: 0;
}

.fecha {
  font-family: "OxygenBold";
  font-size: x-small;
  float: right;
  margin-right: 5px;
}

@font-face {
  font-family: "OxygenRegular";
  src: url("./Oxygen/Oxygen-Regular.ttf")
}

@font-face {
  font-family: "OxygenBold";
  src: url("./Oxygen/Oxygen-Bold.ttf")
}


@font-face {
  font-family: "PlayFairRegular";
  src: url("./Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf")
}

.personas {
  padding-left: 5px;
  font-family: "PlayFairRegular";
}

.playfair {
  font-family: "PlayFairRegular" !important;
  color: black !important;
}